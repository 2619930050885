<!-- 文件描述: 权限管理-角色管理 -->
<!-- 创建时间: 2022/09/14；创建人: 阿苏 -->
<!-- 最后修改时间: 2022/09/14；最后修改人: 阿苏-->
<template>
  <div class="role-manage-page">
    <div class="active">
      <div class="tag-box">
        <div class="tag-item">管理员列表</div>
        <div class="tag-item sign">角色权限</div>
      </div>
      <el-button type="primary" class="add-taskp-btn" @click="addRole">添加角色权限</el-button>
    </div>
    <div class="content">
      <TableModule :tableData="tableData" :page="true" :total="total" @pageChange="pageChange">
        <el-table-column prop="roleName" label="角色名称"> </el-table-column>
        <el-table-column prop="roleRemark" label="角色描述"> </el-table-column>
        <el-table-column prop="address" label="状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.switcState" @change="editState(scope.row)" active-color="#155BD4"
              inactive-color="#C8C9CC">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="editRole(scope.row)">编辑</el-button>
            <el-button type="text" @click="reomve(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </TableModule>
    </div>
    <!-- 角色编辑框 -->
    <el-dialog title="角色" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="角色名称：">
          <el-input v-model="form.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色备注：">
          <el-input v-model="form.roleRemark"></el-input>
        </el-form-item>
        <el-form-item label="角色权限：">
          <div class="menus-item" v-for="(item, index) in menusList" :key="index">
            <el-checkbox v-model="item.check">
              {{ item.menuName }}</el-checkbox>
            <div class="city">
              <el-checkbox v-model="city.check" @change="handleCheckAllChange(city)" v-for="city in item.subMenu"
                :label="city.menuId" :key="city.menuId">
                {{ city.menuName }}</el-checkbox>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-show="activeType=='add'" type="primary" @click="submitAdd">确 定</el-button>
        <el-button v-show="activeType=='edit'" type="primary" @click="submitEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TableModule from "../../components/Table/TableModule.vue";
import * as Apis from "../../http/api/role";
export default {
  name: "AcquisitionsystemList",
  components: {
    TableModule,
  },
  data () {
    return {
      tableData: [],
      total: 0,
      pages: 1,
      dialogVisible: false,
      form: {
        roleName: "",
        roleRemark: "",
        roleId: ""
      },
      menusList: [],
      selMenu: [],
      checkAll: false,
      checkedCities: [],
      activeType: ''
    };
  },
  created () {
    this.getRoleList();
    this.getMenusList();
  },
  mounted () { },
  methods: {
    /**
     * @description 获取角色列表
     * @param
     */
    async getRoleList () {
      try {
        let { code, body } = await Apis.getRoleList({
          pages: this.pages,
          rows: 10,
        });
        if (code == 200) {
          this.tableData = body.content.map((res) => {
            return {
              ...res,
              switcState: res.state == 1 ? true : false,
            };
          });
          this.total = body.total;
        }
      } catch (error) {
        console.error("getRoleList", error);
      }
    },
    /**
     * @description 获取页面菜单
     * @param
     */
    async getMenusList () {
      try {
        let { code, body } = await Apis.getMenusList();
        if (code == 200) {
          this.menusList = this.disposeTree(body)
        }
      } catch (error) {
        console.error("getMenusList", error);
      }
    },
    /**
     * @description 添加角色
     * @param
     */
    addRole () {
      this.dialogVisible = true;
      this.activeType = 'add'
    },
    /**
     * @description 修改角色状态
     * @param
     */
    async editState (item) {
      try {
        let { code } = await Apis.editRoleState({
          roleId: item.roleId,
          state: item.switcState ? 1 : 0,
        });
        if (code == 200) {
          this.$message({
            message: "状态修改成功!",
            type: "success",
          });
          this.getRoleList();
        }
      } catch (error) {
        console.error("editState", error);
      }
    },
    /**
     * @description 编辑角色权限
     * @param 
     */
    editRole (item) {
      this.form.roleName = item.roleName
      this.form.roleRemark = item.roleRemark
      this.dialogVisible = true
      this.form.roleId = item.roleId
      this.activeType = 'edit'
      this.menusList = this.disposeTree(this.menusList, item.menuIds)
    },
    /**
     * @description 提交新建角色
     * @param
     */
    async submitAdd () {
      try {
        let pMenuids = []
        this.menusList.forEach(res => {
          if (res.check) {
            pMenuids.push(res.menuId)
          }
        })
        let menuIds = this.selMenu.map(res => {
          return res.menuId
        })
        menuIds = menuIds.concat(pMenuids)
        let { code } = await Apis.newAddRole({
          roleName: this.form.roleName,
          roleRemark: this.form.roleRemark,
          menuIds: Array.from(new Set(menuIds))
        });
        if (code == 200) {
          this.$message({
            message: "新建角色成功!",
            type: "success",
          });
          this.handleClose()
          this.pages = 1
          this.getRoleList()
        }
      } catch (error) {
        console.error("submitEdit", error);
      }
    },
    /**
    * @description 提交修改角色
    * @param
    */
    async submitEdit () {
      try {
        let pMenuids = []
        this.menusList.forEach(res => {
          if (res.check) {
            pMenuids.push(res.menuId)
          }
        })
        let menuIds = this.selMenu.map(res => {
          return res.menuId
        })
        menuIds = menuIds.concat(pMenuids)
        let { code } = await Apis.EditRole({
          roleId: this.form.roleId,
          roleName: this.form.roleName,
          roleRemark: this.form.roleRemark,
          menuIds: Array.from(new Set(menuIds))
        });
        if (code == 200) {
          this.$message({
            message: "新建角色成功!",
            type: "success",
          });
          this.handleClose()
          this.pages = 1
          this.getRoleList()
        }
      } catch (error) {
        console.error("submitEdit", error);
      }
    },
    /**
     * @description 关闭修改弹框
     * @param
     */
    handleClose () {
      this.dialogVisible = false;
      this.menusList = this.disposeTree(this.menusList)
      this.form.roleName = ''
      this.form.roleRemark = ''
      this.form.roleId = ''
      this.activeType = ''
    },
    /**
     * @description 处理菜单选中的数据
     * @param 
     */
    handleCheckAllChange (val) {
      console.log(val);
      // 0 为一级菜单暂不处理
      if (val.pid != 0) {
        if (val.check) {
          this.selMenu.push({
            pid: val.pid,
            menuId: val.menuId
          })
          // 根据子级来返现父级选中
          for (let index = 0; index < this.menusList.length; index++) {
            if (this.menusList[index].menuId == val.pid) {
              this.menusList[index].check = true
              break
            }
          }
        } else {
          // 过滤掉取消选中的菜单
          this.selMenu = this.selMenu.filter(res => {
            return res.menuId != val.menuId
          })
          let arr = []
          if (this.selMenu) {
            arr = this.selMenu.filter(res => {
              return res.pid == val.pid
            })
          } else {
            this.selMenu = []
          }
          // 当菜单下没有选中的子级页面清除父级的选中
          if (arr.length == 0) {
            for (let index = 0; index < this.menusList.length; index++) {
              if (this.menusList[index].menuId == val.pid) {
                this.menusList[index].check = false
              }
            }
          }
        }
      }
      console.log('selMenu', this.selMenu);
    },
    /**
     * @description 删除角色
     * @param 
     */
    async reomve ({ roleId }) {
      try {
        let { code } = await Apis.deleteRole({
          roleId
        })
        if (code == 200) {
          this.$message({
            message: "角色删除成功!",
            type: "success",
          });
          this.getRoleList()
        }
      } catch (error) {

      }
    },
    /**
     * @description 切换分页
     * @param 
     */
    pageChange (val) {
      this.pages = val
      this.stafflist()
    },
    /**
     * @description 递归数据
     * @param 
     */
    disposeTree (data, menus = []) {
      let arr = []
      arr = data.map(res => {
        let obj = {
          ...res,
          check: menus.includes(res.menuId)
        }
        if (obj.pid != 0 && obj.check) {
          this.selMenu.push({
            pid: obj.pid,
            menuId: obj.menuId
          })
        }
        if (obj.hasOwnProperty('subMenu') && obj.subMenu.length != 0) {
          obj.subMenu = this.disposeTree(obj.subMenu, menus)
        }
        return obj
      })
      return arr
    }
  },
};
</script>

<style lang="scss" scoped>
.role-manage-page {
  padding: 30px 24px;
}

.active {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.add-taskp-btn {
  width: 100px;
  height: 36px;
  background: #507ce5;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  padding: 0;
}

.tag-item {
  width: 150px;
  height: 36px;
  border-radius: 18px;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  line-height: 36px;
}

.tag-box {
  width: 300px;
  height: 36px;
  background: #f7faff;
  border-radius: 18px;
  display: flex;
}

.sign {
  width: 150px;
  height: 36px;
  background: #507ce5;
  border-radius: 18px;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
}

.content {
  margin-top: 20px;
}

/*  菜单 */
.menus-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.city {
  margin-left: 20px;
}
</style>